<template>
  <v-text-field
    ref="gotoentrycode"
    placeholder="type '/' for entrycode"
    class="mr-16"
    color="secondary"
    hide-details
    style="max-width: 220px"
    v-model="gotovalue"
  >
    <template
      v-if="$vuetify.breakpoint.mdAndUp"
      v-slot:append-outer
    >
      <v-btn
        class="mt-n2 ml-n2"
        fab
        small
        elevation="2"
        height="44"
        width="44"
        @click.prevent="submit"
      >
        <!-- <v-icon>mdi-magnify</v-icon> -->
        <v-icon>mdi-arrow-left-bottom-bold</v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
import { isInt } from '@/utils/general.js'

  export default {
    name: 'DefaultSearch',
    data () {
      return {
        gotovalue: ""
      }
    },
    methods: {
      checkgoto(e) {
        let cmd = e.code.toLowerCase();
        if (cmd === 'slash') {
          const goto = this.$refs.gotoentrycode
          this.gotovalue = null
          goto.focus()

          // make sure that '/' does not appear on textfield
          e.preventDefault()
          return
        }
        if (cmd === 'enter') {
          if (this.gotovalue) this.submit()
        }
      },
      async submit() {
        if (!isInt(this.gotovalue)) {
          this.gotovalue = null
          return
        }

        await this.$store.dispatch('incidentsnew/fetchIncidentByEntryCode', this.gotovalue)

        this.$router.push({name: 'ViewIncident', params: {'entrycode': this.gotovalue}})
        this.gotovalue = ""

        // We want to replace the page instead of push, since
        // pushing doesn't refresh the page and this is needed
        // also for fetching the proper incident from the backend
        // this.$router.replace(
        //   // {name: 'Informant', params: {'entrycode': this.gotovalue }},
        //   {name: 'ViewIncident', params: {'entrycode': this.gotovalue }},
        //   () => {
        //     this.$router.go(0);
        //   }
        // )
      }
    },
    created () {
      window.addEventListener('keypress', this.checkgoto)
    },
    destroyed () {
      this.gotovalue = null
      window.removeEventListener('keypress', this.checkgoto)
    }
  }
</script>
